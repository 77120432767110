<script lang="ts" setup>
const { t } = useT();
const { open } = useNlcModals();
</script>

<template>
	<AText
		class="prize-hub text-chaozhou"
		variant="toledo"
		:modifiers="['bold', 'uppercase']"
		@click="open('LazyOModalPrizeHub')"
	>
		<span>{{ t("prize hub") }}</span>
	</AText>
</template>

<style scoped lang="scss">
.prize-hub {
	position: relative;
	height: 44px;
	line-height: 44px;
	background: var(--coimbatore);
	box-shadow: 0px 0px 14px 0px var(--ciudad);
	padding: 0 gutter(2) 0 gutter(6.875);
	border-radius: 8px;
	margin-left: gutter(1);
	cursor: pointer;
	transition: all 200ms ease-in-out;
	&:hover {
		background: var(--cherkasy);
		box-shadow: none;
	}
	&::before {
		content: "";
		position: absolute;
		top: 9px;
		left: 15px;
		width: 36px;
		height: 30px;
		background: transparent url("/nuxt-img/button-prize-hub.png") center center no-repeat;
		background-size: cover;
	}
	@include media-breakpoint-down(lg) {
		border-radius: 8px 0 0 8px;
		padding: 0 24px;
		&::before {
			left: 6px;
		}
		span {
			display: none;
		}
	}
}
</style>
